<template>
  <div class="container">
    <van-nav-bar
      :title="title"
      :left-text="!isSystem ? '返回' : ''"
      :left-arrow="!isSystem"
      @click-left="onClickLeft"
      class="manager-nav"
      v-if="isShow"
    />
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      isSystem: true,
      isShow: false,
    };
  },
  created() {
    this.title = this.$route.meta.name;
    if (this.$route.path !== "/manager-system") {
      this.isSystem = false;
      this.isShow = true;
    } else {
      this.isSystem = true;
      this.isShow = false;
    }
  },
  methods: {
    // 返回
    onClickLeft() {
      if (this.isSystem) return;
      if (this.$route.meta.prevLink) {
        this.$router.push({
          path: this.$route.meta.prevLink,
        });
        return;
      }
      this.$router.go(-1);
    },
  },
  watch: {
    $route(val) {
      this.title = val.meta.name;
      if (val.path !== "/manager-system") {
        this.isSystem = false;
        this.isShow = true;
      } else {
        this.isSystem = true;
        this.isShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon,
/deep/.van-nav-bar__text {
  color: #f3973b;
}
.manager-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.container {
  background-color: #f4f7f9;
  padding-top: 26px;
  min-height: 100%;
  box-sizing: border-box;
}
</style>
